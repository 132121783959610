import React, {Dispatch, FunctionComponent, SetStateAction} from 'react';
import {Button, Image} from "antd";
import type {Database} from "../Core/db_types";
import {Spin} from "antd/lib";
import { format, parseISO } from 'date-fns';

const TaskComponent: FunctionComponent<Database['public']['Tables']['task_info']['Row']> = (props) => {
    return (
        <div className="flex flex-col gap-2 m-2">
            <div>
                <p className="font-bold">New render at {parseISO(props.upload_time!).toLocaleString()}</p>
                <p className="font-medium">Status: {props.status}</p>
                <p className="font-medium">Room Type: {props.room_type}</p>
                <p className="font-medium">Style: {props.style_type}</p>
            </div>
            <div className="flex gap-16 justify-center">
                <div>
                    <div className="flex gap-4">
                        <p>Before</p>
                        <Button type="primary" className="mt-2" onClick={() => window.open(props.orig_url!)}>Download</Button>
                    </div>
                    {
                        props.orig_url ? <Image src={props.orig_url} className="rounded-lg max-w-md"/> : <div/>
                    }

                </div>
                <div className="flex-col place-items-center">
                    <div className="flex gap-4">
                        <p>After</p>
                        <Button type="primary" className="mt-2" onClick={() => window.open(props.result_url!)} disabled={props.result_url == null}>Download</Button>
                    </div>
                    {
                        props.result_url ? <Image src={props.result_url} className="rounded-lg max-w-md"/> : <Spin/>
                    }

                </div>
            </div>

        </div>
    );
};

export default TaskComponent;