import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as amplitude from '@amplitude/analytics-browser';
import axios from "axios";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

let amplitudeKey = process.env.REACT_APP_AMPLITUDE_KEY;
if (amplitudeKey === undefined) {
    amplitudeKey = "3f3e6d6a018b16ac79aebadba9e2c552"
}

amplitude.init(
    amplitudeKey
);

axios.defaults.headers.common['interior-ai-magic-header'] = 'd544321f-6184-44b7-a908-875e6151494d';

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
