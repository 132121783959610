import supabase from "./SupabaseClient";

export class SubscriptionManager {
    userId?: string
    status: boolean

    constructor(userId?: string) {
        this.userId = userId
        this.status = false
    }

    async updateStatus() {
        if (this.userId == undefined) {
            this.status = false
            return
        }

        if (process.env.NODE_ENV === "development") {
            let { data, error } = await supabase
                .from("sub_state_debug")
                .select('*')
                .eq('client_reference_id', this.userId)

            const premium = data?.at(0)?.premium
            if (premium) {
                this.status = premium
                return
            }

            this.status = false
        } else {
            let { data, error } = await supabase
                .from("sub_state")
                .select('*')
                .eq('client_reference_id', this.userId)

            const premium = data?.at(0)?.premium
            if (premium) {
                this.status = premium
                return
            }

            this.status = false
        }
    }

}