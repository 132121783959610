import React, {useEffect, useState} from 'react';
import InteriorAppForm from "../Components/InteriorAppForm";
import TaskComponent from "../Components/TaskComponent";
import {User} from "@supabase/gotrue-js/src/lib/types";
import {Database} from "../Core/db_types";
import {SubscriptionManager} from "../Core/SubscriptionManager";
import supabase from "../Core/SupabaseClient";
import {Button, Spin} from "antd";
import SignInComponent from "../Components/SignInComponent";
import * as amplitude from '@amplitude/analytics-browser';

const AppPage = () => {

    const [loadingUser, setLoadingUser] = useState(true);
    const [user, setUser] = useState<User | null | undefined>(null);

    const [tasks, setTasks] = useState<Array<Database['public']['Tables']['task_info']['Row']>>([]);

    const [subStatus, setSubStatus] = useState<boolean>(false);
    const subManager = new SubscriptionManager(user?.id)

    const subState = supabase.channel('custom-sub-channel')
        .on(
            'postgres_changes',
            { event: '*', schema: 'public', table: process.env.REACT_APP_SUB_BASE_NAME },
            (payload) => {
                updateSubStatus();
            }
        )
        .subscribe();

    async function updateSubStatus() {
        await subManager.updateStatus();
        setSubStatus(subManager.status);

        if (subManager.status) {
            // @ts-ignore
            window.pintrk("track", "checkout", {
                value: 4.99,
                order_quantity: 1,
                currency: 'USD'
            });

            amplitude.track('BoughtPremium');
        }
    }

    async function updateTasks() {
        if (user?.id == undefined) { return }

        if (process.env.NODE_ENV === "development") {
            let { data, error } = await supabase
                .from('task_info_debug')
                .select('*')
                .eq('user_id', user?.id)
                .order('upload_time', { ascending: false })

            if (data) {
                setTasks(data);
            }
        } else {
            let { data, error } = await supabase
                .from('task_info')
                .select('*')
                .eq('user_id', user?.id)
                .order('upload_time', { ascending: false })

            if (data) {
                setTasks(data);
            }
        }
    }

    async function signOut() {
        if (!user) return;
        setLoadingUser(true);

        const resp = await supabase.auth.signOut();
        setLoadingUser(false);

        if (resp.error) {
            alert(resp.error);
            return;
        }

        setUser(null);
        window.localStorage.removeItem('userEmailForLogin');
    }

    useEffect(() => {
        updateTasks();

        if (user === undefined) return;
        if (user === null) return;

        amplitude.track('SignedIn');
        amplitude.setUserId(user.email);

        // @ts-ignore
        if (typeof window !== "undefined" && window.hj) {
            // @ts-ignore
            window.hj("identify", user.id, {
                "email": user.email
            });

            // @ts-ignore
            window.pintrk("track", "signup", {});
        }

    }, [user])

    useEffect(() => {
        supabase.auth.getSession().then ( resp => {
            setLoadingUser(false);
            if (resp.error) {
                alert(resp.error);
                return
            }

            setUser(resp.data.session?.user)
            subManager.userId = resp.data.session?.user?.id

            updateSubStatus();
        })
    }, [])

    const taskInfo = supabase.channel('custom-task-channel')
        .on(
            'postgres_changes',
            { event: '*', schema: 'public', table: process.env.REACT_APP_TASK_BASE_NAME },
            (payload) => {
                updateTasks();
            }
        )
        .subscribe()

    return (
        <div className="flex flex-col gap-1 justify-center mx-8 my-4 text-left text-xs md:text-base">
            {
                user ? <div>
                    <p>Hey, {user?.email}</p>
                    <p>Your plan: <b>{subStatus ? "Pro" : "Free"}</b></p>
                </div> : <div/>
            }

            {
                loadingUser ? <Spin/> :
                    user
                        ? <Button onClick={signOut}> Sign out </Button>
                        : <SignInComponent/>
            }

            {
                user ? <div>
                    <InteriorAppForm subStatus={subStatus} user={user}/>
                    <h2 hidden={tasks.length == 0}>Your Renders</h2>
                    {
                        tasks.map( task => {
                            return <TaskComponent {...task}/>
                        })
                    }
                </div>
                    : <div/>
            }

        </div>
    );
};

export default AppPage;