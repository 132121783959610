import React, {Dispatch, FunctionComponent, SetStateAction, useState} from 'react';
import {Button, Form, Input} from "antd";

import {MailOutlined, AppleOutlined, GoogleOutlined} from "@ant-design/icons";

import supabase from "../Core/SupabaseClient";
import * as amplitude from "@amplitude/analytics-browser";

const SignInComponent = () => {
    const [form] = Form.useForm<{ email: string; password: string;}>();
    const emailValue = Form.useWatch('email', form);

    async function signInWithApple() {
        amplitude.track('SignInTap', {"type" : "apple"});
        const { data, error } = await supabase.auth.signInWithOAuth({
            provider: 'apple',
            options: {
                redirectTo: 'https://interiorai.pro/app'
            }
        })
    }

    async function signInWithGoogle() {
        amplitude.track('SignInTap', {"type" : "google"});
        const { data, error } = await supabase.auth.signInWithOAuth({
            provider: 'google',
            options: {
                redirectTo: 'https://interiorai.pro/app'
            }
        })
    }

    return (
        <div className="sticky top-0 bg-white z-40 pb-2">

            <div className="flex flex-col gap-2 items-center text-center">
                <h1 className="max-w-2xl text-4xl font-extrabold md:text-5xl xl:text-6xl">
                    Interior Design by AI
                </h1>

                <h2 className="max-w-2xl font-light text-gray-600 md:text-lg lg:text-xl">
                    Choose preferred option to start using Interior AI
                </h2>

                <Button
                    id="apple-sign-in"
                    className="w-4/5"
                    onClick={signInWithApple}
                    style={{ background: "black", borderColor: "white" }}
                    type={"primary"}
                    icon={<AppleOutlined />}
                    size={"large"}
                >
                    Sign In With Apple
                </Button>

                <Button
                    id="google-sign-in"
                    className="w-4/5"
                    onClick={signInWithGoogle}
                    icon={<GoogleOutlined />}
                    size={"large"}
                >
                    Sign In With Google
                </Button>
            </div>

        </div>
    );
};

export default SignInComponent;