import React, {FC} from 'react';

import cameraImg from "../Resources/Camera search.png";
import settingsImg from "../Resources/preferences.png";
import resultImg from "../Resources/Check badge.png";
import LaunchAppButton from "../Components/LaunchAppButton";

interface StepProps {
    title: string,
    subtitle: string,
    image: string
}

const StepCard: FC<StepProps> = (props) => {
    return (
        <div className="flex flex-col gap-4 bg-white rounded-3xl border-solid border-spacing-0.5 border-red-700 p-4 text-left">
            <img className="w-8" src={props.image}/>
            <div className="text-zinc-900 text-xl font-bold --webkit-font-['Unbounded'] leading-10">{props.title}</div>
            <div className="text-zinc-600 text-lg font-normal --webkit-font-['Onest'] leading-normal">{props.subtitle}</div>
        </div>
    );
};

const HowToBlock = () => {
    return (
        <div className="flex flex-col justify-start items-start gap-4 w-full px-8 xl:px-48 py-8 text-left">
            <div className="justify-start items-center">
                <h2 className="text-3xl font-extrabold md:text-4xl xl:text-5xl font-bold leading-10">
                    Reimagine Your Space with Our Free AI Room Design
                </h2>
            </div>
            <p className="text-gray-700 text-2xl font-normal leading-loose">
                Whether it's a bedroom, living room, or kitchen, our <strong>AI room designer</strong> can handle it all. Just upload your photo, choose your desired style and room type, and let our <strong>artificial intelligence room design</strong> technology do the rest.
            </p>
            <div className="flex flex-col md:flex-row gap-2 justify-between w-full place-items-center">
                <StepCard title="Upload Your Space" subtitle="Take or upload your room's photo" image={cameraImg}/>
                <StepCard title="Setup Preferences" subtitle="Select interior style & room type" image={settingsImg}/>
                <StepCard title="Get the result!" subtitle="Enjoy a render of renovated space" image={resultImg}/>
            </div>
            <div className="w-full my-8 md:my-16 max-w-md self-center">
                <LaunchAppButton title={"Try Now"}/>
            </div>
        </div>
    );
};

export default HowToBlock;