import Dragger from 'antd/es/upload/Dragger';
import React, {useEffect, useState} from 'react';

import {InboxOutlined} from "@ant-design/icons";
import DataSelectComponent from "../Components/DataSelectComponent";
import { auth } from '../firebase';
import {useSendSignInLinkToEmail, useSignInWithEmailLink} from "react-firebase-hooks/auth";
import SignInComponent from "../Components/SignInComponent";
import {isSignInWithEmailLink, sendSignInLinkToEmail} from "firebase/auth";

import { createClient } from "@supabase/supabase-js";
import {Session, User} from "@supabase/gotrue-js/src/lib/types";
import {uuid} from "@supabase/supabase-js/dist/main/lib/helpers";
import TaskComponent from "../Components/TaskComponent";
import supabase from "../Core/SupabaseClient";

import type {Database} from "../Core/db_types";
import {roomTypeOptions} from "../Core/Data/RoomType";
import {styleOptions} from "../Core/Data/StyleType";
import {RcFile, UploadChangeParam } from 'antd/es/upload';
import BuyButtonComponent from "../Components/BuyButtonComponent";
import {SubscriptionManager} from "../Core/SubscriptionManager";
import { PlusOutlined } from '@ant-design/icons';
import {
    Button,
    Cascader,
    Checkbox,
    DatePicker,
    Form,
    Input,
    InputNumber,
    Radio,
    Select,
    Switch,
    TreeSelect,
    Upload,
    Spin,
    Image,
    message,
    UploadProps,
    Steps,
    Space,
    Layout
} from 'antd';
import InteriorAppForm from "../Components/InteriorAppForm";
import LatestRenders from "../Components/LatestRenders";
import HeroBlock from "../Blocks/HeroBlock";
import StatisticsPanel from "../Components/StatisticsPanel";
import {ReactCompareSlider, ReactCompareSliderHandle, ReactCompareSliderImage} from "react-compare-slider";
import TestimonialsBlock from "../Blocks/TestimonialsBlock";
import {useNavigate} from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";
import HowToBlock from "../Blocks/HowToBlock";
import SamplesBlock from "../Blocks/SamplesBlock";
import CTABlock from "../Blocks/CTABlock";


const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Content } = Layout;

const LandingPage = () => {
    return (
        <div className="bg-neutral-900">
            <Space direction="vertical" style={{ width: '100%' }}>
                <Layout>
                    <Content>
                        <HeroBlock/>
                        <HowToBlock/>
                        <SamplesBlock/>
                        <TestimonialsBlock/>
                        <CTABlock/>
                    </Content>
                </Layout>
            </Space>
        </div>
    );
};

export default LandingPage;