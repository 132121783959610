import React, {FC} from 'react';

interface ArticlePreviewProps {
    title: string,
    subtitle: string,
    published: string,
    publishedDateTime: string,
    image: string
}

const ArticlePreviewComponent: FC<ArticlePreviewProps> = (props) => {
    return (
        <div className="flex flex-col gap-0 bg-white rounded-2xl border-solid border-spacing-0.5 border-red-700 p-4 text-left max-w-sm">
            <img className="w-80 rounded-2xl" src={props.image}/>
            <p className="text-zinc-900 text-xl font-bold">{props.title}</p>
            <p>Published on: <time dateTime={props.publishedDateTime}>{props.published}</time></p>
            <p className="text-zinc-700 font-normal">{props.subtitle}</p>
        </div>
    );
};

export default ArticlePreviewComponent;