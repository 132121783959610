import React from 'react';
import {Button} from "antd";
import {useNavigate} from "react-router-dom";
import StatisticsPanel from "../Components/StatisticsPanel";
import * as amplitude from "@amplitude/analytics-browser";
import interiorImg from "../Resources/ai-decor-matters.webp";

const LeftPart = () => {
    const navigate = useNavigate();

    return (
        <div className="flex flex-col-reverse md:flex-col justify-start items-start gap-8 text-left">
            <div className="self-stretch flex-col justify-start items-start gap-8 flex">
                <div className="self-stretch flex-col justify-start items-start gap-1 flex">
                    <h1 className="text-4xl font-extrabold md:text-5xl xl:text-6xl">
                        Your AI Interior Design Solution
                    </h1>
                    <p className="max-w-2xl font-normal text-gray-700 text-2xl">
                        Experience the future of <strong>interior design</strong> with <a href="https://interiorai.pro">interiorai.pro</a>. Our advanced <strong>AI interior design</strong> technology transforms your space from just a single photo. <br/>No need for expensive and time-consuming consultations — our <strong>AI room design</strong> tool lets you reimagine your space in an instant.
                    </p>
                </div>
            </div>
            <div className="self-stretch items-center justify-center md:items-start md:justify-start">
                <Button
                    className="w-full"
                    type="primary"
                    size="large"
                    onClick={() => {
                        amplitude.track('GetStarted');
                        navigate("/app");
                    }}
                >
                    Get Started
                </Button>
            </div>
        </div>
    );
};

const HeroBlock = () => {
    return (
        <div className="flex flex-col-reverse md:flex-row justify-start items-center gap-16 px-8 xl:px-48 py-8 xl:py-16">
            <LeftPart/>
            <img src={interiorImg} className="w-2/3 md:w-1/3 rounded-3xl md:mt-12"/>
        </div>
    );
};

export default HeroBlock;