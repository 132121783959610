import React from 'react';
import {Button, Modal} from "antd";

const PricingTableComponent = ({client_id, customer_email, isModalOpen, setIsModalOpen}) => {
    return (
        <Modal open={isModalOpen} cancelText={"Close"} closable={false} footer={[<Button key="back" onClick={() => setIsModalOpen(false)}>
            Close
        </Button>]}>
            <stripe-pricing-table
                client-reference-id={client_id}
                customer-email={customer_email}
                pricing-table-id={process.env.REACT_APP_PRICING_TABLE_ID}
                publishable-key={process.env.REACT_APP_STRIPE_PK_KEY}
            />
        </Modal>
    );
};

export default PricingTableComponent;