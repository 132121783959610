import React from 'react';
import {ReactCompareSlider, ReactCompareSliderHandle, ReactCompareSliderImage} from "react-compare-slider";
import testImg from "../Resources/ai-decor-matters.webp";
import LaunchAppButton from "../Components/LaunchAppButton";

const SamplesBlock = () => {
    return (
        <div>

            <div className="flex flex-col justify-start items-start gap-4 w-full px-8 xl:px-48 py-8 text-left">
                <h2 className="text-3xl font-extrabold md:text-4xl xl:text-5xl font-bold leading-10">
                    AI Home Design – The Future is Here
                </h2>

                <p className="text-gray-700 text-2xl font-normal leading-loose">
                    At <a href="https://interiorai.pro">InteriorAI.Pro</a>, we are proud to bring you the future of home improvement. Our <strong>AI home design</strong> technology allows you to see the potential of your space without stepping foot into a physical store.
                </p>

                <div className="flex flex-col md:flex-row gap-2 justify-between w-full place-items-center">
                    <ReactCompareSlider
                        handle={<ReactCompareSliderHandle buttonStyle={{display: 'none'}} linesStyle={{height: '100%', width: 4}}/>}
                        className="rounded-2xl w-1/4 max-sm:w-10/12"
                        position={50}
                        itemOne={<ReactCompareSliderImage
                            src="https://emfrdafrvykdgcfuqosn.supabase.co/storage/v1/object/public/render-placeholders/f517bea16be21b86c9a974e362f86ef0.webp"
                            alt="Before"
                        />}
                        itemTwo={<ReactCompareSliderImage
                            src="https://emfrdafrvykdgcfuqosn.supabase.co/storage/v1/object/public/render-placeholders/ImgCreator.ai-2023-04-06T210419.822-min.webp"
                            alt="After"
                        />}
                    />

                    <ReactCompareSlider
                        handle={<ReactCompareSliderHandle buttonStyle={{display: 'none'}} linesStyle={{height: '100%', width: 4}}/>}
                        className="rounded-2xl w-1/4 max-sm:w-10/12"
                        position={50}
                        itemOne={<ReactCompareSliderImage
                            src="https://emfrdafrvykdgcfuqosn.supabase.co/storage/v1/object/public/render-placeholders/origin2.webp"
                            alt="Before"
                        />}
                        itemTwo={<ReactCompareSliderImage
                            src="https://emfrdafrvykdgcfuqosn.supabase.co/storage/v1/object/public/render-placeholders/render2.webp"
                            alt="After"
                        />}
                    />

                    <ReactCompareSlider
                        handle={<ReactCompareSliderHandle buttonStyle={{display: 'none'}} linesStyle={{height: '100%', width: 4}}/>}
                        className="rounded-2xl w-1/4 max-sm:w-10/12"
                        position={50}
                        itemOne={<ReactCompareSliderImage
                            src="https://emfrdafrvykdgcfuqosn.supabase.co/storage/v1/object/public/render-placeholders/origin1.webp"
                            alt="Before"
                        />}
                        itemTwo={<ReactCompareSliderImage
                            src="https://emfrdafrvykdgcfuqosn.supabase.co/storage/v1/object/public/render-placeholders/render1.webp"
                            alt="After"
                        />}
                    />
                </div>

                <div className="w-full my-8 md:my-16 max-w-md self-center">
                    <LaunchAppButton title={"Renovate your space"}/>
                </div>
            </div>

        </div>
    );
};

export default SamplesBlock;