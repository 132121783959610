export enum StyleType {
    Modern = "modern",
    Minimalist = "minimalist",
    Tropical = "tropical",
    Zen = "zen",
    Scandinavian = "scandinavian"
}

export const styleOptions = [
    {
        value: StyleType.Modern,
        label: 'Modern',
    },
    {
        value: StyleType.Minimalist,
        label: 'Minimalist',
    },
    {
        value: StyleType.Tropical,
        label: 'Tropical',
    },
    {
        value: StyleType.Zen,
        label: 'Zen',
    },
    {
        value: StyleType.Scandinavian,
        label: 'Scandinavian',
    },
];