import React, {Dispatch, FunctionComponent, SetStateAction, useState} from 'react';
import {
    Form,
    Input,
    Button,
    Select,
    Upload,
    message,
    Checkbox,
    Radio,
    TreeSelect,
    Cascader,
    DatePicker,
    InputNumber, Switch, UploadProps
} from 'antd';
import {PlusOutlined, UploadOutlined} from '@ant-design/icons';
import axios from 'axios';
import {UploadChangeParam, UploadFile} from "antd/es/upload/interface";
import {roomTypeOptions} from "../Core/Data/RoomType";
import {styleOptions} from "../Core/Data/StyleType";
import BuyButtonComponent from "./BuyButtonComponent";
import {User} from "@supabase/gotrue-js/src/lib/types";
import {RcFile} from "antd/es/upload";
import PricingTableComponent from "./PricingTableComponent";
import * as amplitude from "@amplitude/analytics-browser";

const { Option } = Select;

interface IProps {
    subStatus: boolean;
    user: User | null | undefined;
}

const InteriorAppForm: FunctionComponent<IProps> = (props) => {

    const [form] = Form.useForm();
    const roomType = Form.useWatch('roomType', form);
    const style = Form.useWatch('styleType', form);
    const [file, setFile] = useState<UploadFile | null>(null);
    const [uploading, setUploading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const normFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };

    const handleUpload = () => {
        if (!file) { alert("Please, select a photo first!"); return }
        if (!roomType) { alert("Please, select a room type first!"); return }
        if (!style) { alert("Please, select a style first!"); return }

        const formData = new FormData();
        formData.append('image', file as RcFile);
        setUploading(true);

        axios.post(process.env.REACT_APP_BACKEND_PATH + 'generate?room_type=' + roomType + '&style_type=' + style + '&user_id=' + props.user?.id, formData)
            .then((res ) => {
                message.success('Uploaded successfully. Check "Your Renders" section to see the result. 👇')
            }).catch(() => {
                message.error('upload failed.');
            }).finally(() => {
                setUploading(false);
        });
    };

    const uProps: UploadProps = {
        onRemove: (file) => {
            setFile(null);
        },
        beforeUpload: (file) => {
            setFile(file);
            return false;
        }
    };

    return (
        <div>
        <Form
            form={form}
            layout="vertical"
        >
            <h3 className="font-bold text-lg">
                Your current interior
            </h3>
            <Form.Item
                name="photo"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[
                    { required: true, message: 'Please upload a photo' }
                ]}
                className="w-full justify-center"
            >
                <Upload
                    name="photo"
                    listType="picture-card"
                    maxCount={1}
                    {...uProps}
                >
                    {file ? <img src={file.url} style={{ width: '100%' }} /> : <UploadOutlined/>}
                </Upload>
            </Form.Item>
            <p>
                Take a photo of your current room. For best results make sure it shows the entire room in a straight angle facing a wall or window horizontally. Uploads + renders are shown below.
            </p>

            <h3 className="font-bold text-left text-lg">
                Room Type
            </h3>
            <Form.Item
                name="roomType"
                rules={[{ required: true, message: 'Please select a room type!' }]}
            >
                <Select placeholder="Select a room type" options={roomTypeOptions}/>
            </Form.Item>

            <h3 className="font-bold text-left text-lg">
                Style Type
            </h3>
            <Form.Item
                name="styleType"
                rules={[{ required: true, message: 'Please select a style type!' }]}
            >
                <Select placeholder="Select a style type" options={styleOptions}/>
            </Form.Item>

            <p>
                Pro Tip: if you like a generated image, you can re-use it as an input for more results like it.
            </p>
        </Form>
            <Button
                type="primary"
                size="large"
                loading={uploading}
                disabled={uploading}
                onClick={() => {
                    amplitude.track('RenderTap');

                    if (!props.user) {
                        if (!window.localStorage.getItem('userEmailForLogin')) {
                            // alert("Enter and validate your email to continue!");
                            alert("Sign In to Start Using Interior AI")
                            document?.getElementById("apple-sign-in")?.focus();
                        } else {
                            // alert("Validate your email to continue!")
                            alert("Sign In to Start Using Interior AI")
                        }
                    } else {
                        if (!props.subStatus) {
                            setIsModalOpen(true);
                        } else {
                            handleUpload();
                        }
                    }
                }}
            >
                Render new interior
            </Button>

            <PricingTableComponent client_id={props.user?.id} customer_email={props.user?.email} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}/>
        </div>
    );
};

export default InteriorAppForm;
