export enum RoomType {
    Bedroom = "bedroom",
    LivingRoom = "living room",
    Bathroom = "bath room",
    Kitchen = "kitchen",
    Dining = "dining room",
    Study = "study room"
}

export const roomTypeOptions = [
    {
        value: RoomType.LivingRoom,
        label: 'Living room',
    },
    {
        value: RoomType.Bedroom,
        label: 'Bedroom',
    },
    {
        value: RoomType.Bathroom,
        label: 'Bathroom',
    },
    {
        value: RoomType.Kitchen,
        label: 'Kitchen',
    },
    {
        value: RoomType.Dining,
        label: 'Dining room',
    },
    {
        value: RoomType.Study,
        label: 'Study room',
    },
];