import React from 'react';
import {ReactCompareSlider, ReactCompareSliderHandle, ReactCompareSliderImage} from "react-compare-slider";
import LaunchAppButton from "../Components/LaunchAppButton";

const CtaBlock = () => {
    return (
        <div>

            <div className="flex flex-col justify-start items-start gap-2 w-full px-8 xl:px-48 py-8 text-left">
                <h2 className="text-3xl font-extrabold md:text-4xl xl:text-5xl font-bold leading-10">
                    Try Out Our AI Room Planner Today
                </h2>

                <p className="text-gray-700 text-2xl font-normal leading-loose">
                    Take the guesswork out of your next redesign project. Use our <strong>AI room planner</strong> to visualize different layouts and styles. It's time to reimagine your home with <strong>InteriorAI.Pro</strong>.
                </p>

                <div className="w-full my-8 md:my-16 max-w-md self-center">
                    <LaunchAppButton title={"Renovate your space"}/>
                </div>
            </div>

        </div>
    );
};

export default CtaBlock;