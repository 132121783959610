import React from 'react';
import {Layout, Space} from "antd";
import LaunchAppButton from "../Components/LaunchAppButton";
import ArticlePreviewComponent from "../Components/ArticlePreviewComponent";

const { Content } = Layout;

const ArticlePage = () => {
    return (
        <div className="bg-neutral-900">
            <Space direction="vertical" style={{ width: '100%', height: "100%" }}>
                <Layout>
                    <Content>
                        <article className="text-left p-12 md:p-16 text-gray-900 text-lg font-normal leading-loose">
                            <h2 className="text-3xl font-extrabold md:text-4xl xl:text-5xl font-bold leading-10">Reimagine Your Space with AI Interior Design</h2>
                            <p className="text-red-300 text-md font-normal leading-loose">Published on: <time dateTime="2023-11-29">November 29, 2023</time></p>

                            <p>Interior design has always been a realm of creativity and innovation, but with the advent
                                of <strong>AI interior design</strong>, the possibilities are expanding exponentially.
                                With <a href="https://interiorai.pro">InteriorAI.Pro</a>'s advanced <strong>AI room
                                    design</strong> technology, you can reimagine your space in ways you never thought
                                possible.</p>

                            <h3 className="text-xl font-extrabold md:text-2xl xl:text-3xl font-bold leading-10">What is AI Interior Design?</h3>
                            <p><strong>AI interior design</strong> uses artificial intelligence to generate design ideas
                                based on user preferences and trends. The AI understands various elements like color
                                schemes, furniture arrangements, and decor styles, and uses this information to create
                                unique designs. It's like having an interior designer in your pocket.</p>

                            <h3 className="text-xl font-extrabold md:text-2xl xl:text-3xl font-bold leading-10">The Future of Home Design</h3>
                            <p>Imagine being able to revamp your home without the need for lengthy consultations or
                                expensive designer fees. With <strong>AI home design</strong>, this is now a reality.
                                Whether you're looking to spruce up a single room or redesign your entire home, AI
                                technology can help you visualize the potential of your space in a matter of seconds.
                            </p>

                            <h3 className="text-xl font-extrabold md:text-2xl xl:text-3xl font-bold leading-10">A Revolutionary AI Room Planner</h3>
                            <p>One of the standout features of <a href="https://interiorai.pro">InteriorAI.Pro</a> is
                                the <strong>AI room planner</strong>. This tool allows you to experiment with different
                                room layouts and styles, making it easier to plan your renovation or redecoration
                                project. You can experiment with different layouts, furniture, and decor items, all with
                                the help of AI.</p>

                            <h3 className="text-xl font-extrabold md:text-2xl xl:text-3xl font-bold leading-10">Reimagine Your Space Today</h3>
                            <p>The future of interior design is here. With <a
                                href="https://interiorai.pro">InteriorAI.Pro</a>'s <strong>AI room
                                design</strong> technology, you can reimagine your space and create a home that truly
                                reflects your style and personality. Try it for free today and experience the revolution
                                in interior design.</p>
                        </article>

                    </Content>
                </Layout>
            </Space>

        </div>

    );
};

export default ArticlePage;