import React from 'react';
import {Rate} from "antd";
import testImg from "../Resources/ai-decor-matters.webp";
import LaunchAppButton from "../Components/LaunchAppButton";

const TestimonialsBlock = () => {
    return (
        <div className="flex flex-col justify-start items-start gap-4 w-full px-8 xl:px-48 py-8 text-left">
            <div className="justify-start items-center">
                <div className="text-3xl font-extrabold md:text-4xl xl:text-5xl font-bold leading-10">What our users saying</div>
            </div>
            <div className="text-gray-700 text-2xl font-normal leading-loose">Over 5000 users rated Interior AI app on all major platforms</div>
            <div className="flex flex-col md:flex-row gap-2 justify-between w-full place-items-center">
                <div className="max-w-screen-xl px-4 py-2 mx-auto text-center">
                    <figure className="max-w-3xl mx-auto">
                        <blockquote>
                            <Rate disabled defaultValue={5} />
                            <p className="text-xl font-medium text-gray-900">
                                "Interior AI completely reimagined my living room. I never thought it could look this stylish and functional!"
                            </p>
                        </blockquote>
                        <figcaption className="flex items-center justify-center mt-6 space-x-3">
                            <img className="w-6 h-6 rounded-full"
                                 src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/karen-nelson.png"
                                 alt="profile picture"/>
                            <div className="flex items-center divide-x-2 divide-gray-500">
                                <div className="pr-3 font-medium text-gray-900">Sarah M.</div>
                            </div>
                        </figcaption>
                    </figure>
                </div>

                <div className="max-w-screen-xl px-4 py-2 mx-auto text-center">
                    <figure className="max-w-3xl mx-auto">
                        <blockquote>
                            <Rate disabled defaultValue={5} />
                            <p className="text-xl font-medium text-gray-900">
                                "The AI-driven design process saved me so much time and money. My home now reflects my personal style perfectly."
                            </p>
                        </blockquote>
                        <figcaption className="flex items-center justify-center mt-6 space-x-3">
                            <img className="w-6 h-6 rounded-full"
                                 src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/jese-leos.png"
                                 alt="profile picture"/>
                            <div className="flex items-center divide-x-2 divide-gray-500">
                                <div className="pr-3 font-medium text-gray-900">Tom H.</div>
                            </div>
                        </figcaption>
                    </figure>
                </div>

                <div className="max-w-screen-xl px-4 py-2 mx-auto text-center">
                    <figure className="max-w-3xl mx-auto">
                        <blockquote>
                            <Rate disabled defaultValue={5} />
                            <p className="text-xl font-medium text-gray-900">
                                "Working with Interior AI was a game-changer. Their expert guidance and innovative technology made the entire process a breeze."
                            </p>
                        </blockquote>
                        <figcaption className="flex items-center justify-center mt-6 space-x-3">
                            <img className="w-6 h-6 rounded-full"
                                 src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/roberta-casas.png"
                                 alt="profile picture"/>
                            <div className="flex items-center divide-x-2 divide-gray-500">
                                <div className="pr-3 font-medium text-gray-900">Amanda G.</div>
                            </div>
                        </figcaption>
                    </figure>
                </div>
            </div>
        </div>
    );
};

export default TestimonialsBlock;