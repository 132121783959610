import React from 'react';
import './App.css';
import LandingPage from "./Pages/LandingPage";
import {ConfigProvider} from "antd";
import {Route, BrowserRouter, Routes} from "react-router-dom";
import AppPage from "./Pages/AppPage";
import Privacy from './Components/Privacy';
import Terms from "./Components/Terms";
import BlogPage from './Pages/BlogPage';
import ArticlePage from "./Pages/ArticlePage";

function App() {
  return (
      <ConfigProvider
          theme={{ token: { colorPrimary: '#E0484B' } }}
      >
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route key="landing" path="/" element={<LandingPage />}/>
                    <Route key="app" path="/app" element={<AppPage />}/>
                    <Route key="privacy" path="/privacy" element={<Privacy />}/>
                    <Route key="terms" path="/terms" element={<Terms />}/>
                    <Route key="blog" path="/blog" element={<BlogPage />}/>
                    <Route key="article-1" path="/article-1" element={<ArticlePage />}/>
                </Routes>
            </BrowserRouter>
        </div>
      </ConfigProvider>
  );
}

export default App;
