import React, {FC} from 'react';
import {Button} from "antd";
import * as amplitude from "@amplitude/analytics-browser";
import {useNavigate} from "react-router-dom";

interface LaunchAppProps {
    title: string
}

const LaunchAppButton: FC<LaunchAppProps> = (props) => {
    const navigate = useNavigate();

    return (
        <div className="self-stretch">
            <Button
                className="w-full"
                type="primary"
                size="large"
                onClick={() => {
                    amplitude.track('GetStarted');
                    navigate("/app");
                }}
            >
                {props.title}
            </Button>
        </div>
    );
};

export default LaunchAppButton;