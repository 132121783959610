import React from 'react';
import LaunchAppButton from "../Components/LaunchAppButton";
import ArticlePreviewComponent from "../Components/ArticlePreviewComponent";
import {Layout, Space} from "antd";

const { Content } = Layout;

const BlogPage = () => {
    return (
        <div className="bg-neutral-900">
            <Space direction="vertical" style={{ width: '100%' }}>
                <Layout>
                    <Content>
                        <div className="flex flex-col justify-start items-start gap-0 w-full text-left">
                            <div className="justify-start items-center px-8">
                                <h1 className="text-3xl font-extrabold md:text-4xl xl:text-5xl font-bold leading-10">
                                    Welcome to the InteriorAI.Pro Blog
                                </h1>
                            </div>
                            <div className="w-full my-4 md:my-8 max-w-sm self-center md:self-start md:px-8">
                                <LaunchAppButton title={"Try App"}/>
                            </div>
                            <div className="w-full mt-8 mb-16 flex justify-center md:justify-start md:px-8">
                                <ArticlePreviewComponent title={"Test"} subtitle={"Lorem ipsum"} published={"November 29, 2023"} publishedDateTime={"2023-11-29"} image={"https://random.imagecdn.app/500/300"}/>
                            </div>
                        </div>
                    </Content>
                </Layout>
            </Space>

        </div>

    );
};

export default BlogPage;